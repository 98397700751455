import * as i0 from '@angular/core';
import { EventEmitter, Component, Input, Output, ViewChild, NgModule } from '@angular/core';
import Typed from 'typed.js';
const _c0 = ["wrapper"];
const _c1 = ["*"];
class NgxTypedJsComponent {
  constructor() {
    this.typeSpeed = 30;
    this.completed = new EventEmitter();
    this.preStringTyped = new EventEmitter();
    this.stringTyped = new EventEmitter();
    this.lastStringBackspaced = new EventEmitter();
    this.typingPaused = new EventEmitter();
    this.typingResumed = new EventEmitter();
    this.reset = new EventEmitter();
    this.stopped = new EventEmitter();
    this.started = new EventEmitter();
    this.destroyed = new EventEmitter();
  }
  ngAfterViewInit() {
    this.typed = new Typed(this.content.nativeElement.querySelector('.typing'), this.options);
    if (this.showCursor !== false) {
      this.updateCursorStyle();
    }
  }
  toggle() {
    this.typed.toggle();
  }
  stop() {
    this.typed.stop();
  }
  start() {
    this.typed.start();
  }
  destroy() {
    this.typed.destroy();
  }
  doReset(restart) {
    this.typed.reset(restart);
  }
  get options() {
    const emit = emitter => () => emitter.emit();
    const emitIndex = emitter => index => emitter.emit(index);
    const opts = {
      strings: this.strings ?? [''],
      stringsElement: this.stringsElement,
      typeSpeed: this.typeSpeed,
      startDelay: this.startDelay,
      backSpeed: this.backSpeed,
      smartBackspace: this.smartBackspace,
      shuffle: this.shuffle,
      backDelay: this.backDelay,
      fadeOut: this.fadeOut,
      fadeOutClass: this.fadeOutClass,
      fadeOutDelay: this.fadeOutDelay,
      loop: this.loop,
      loopCount: this.loopCount,
      showCursor: this.showCursor,
      autoInsertCss: this.autoInsertCss,
      attr: this.attr,
      bindInputFocusEvents: this.bindInputFocusEvents,
      contentType: this.contentType,
      onComplete: emit(this.completed),
      preStringTyped: emitIndex(this.preStringTyped),
      onStringTyped: emitIndex(this.stringTyped),
      onLastStringBackspaced: emit(this.lastStringBackspaced),
      onTypingPaused: emitIndex(this.typingPaused),
      onTypingResumed: emitIndex(this.typingResumed),
      onReset: emit(this.reset),
      onStop: emitIndex(this.stopped),
      onStart: emitIndex(this.started),
      onDestroy: emit(this.destroyed)
    };
    Object.keys(opts).forEach(key => {
      if (opts[key] === undefined) {
        delete opts[key];
      }
    });
    return opts;
  }
  updateCursorStyle() {
    const textElementStyle = getComputedStyle(this.content.nativeElement.querySelector('.typing'));
    const cursorElementStyle = this.content.nativeElement.querySelector('.typed-cursor').style;
    cursorElementStyle.fontSize = textElementStyle.fontSize;
    cursorElementStyle.color = this.cursorColor || textElementStyle.color;
  }
  ngOnChanges(changes) {
    if (this.typed) {
      this.typed.destroy();
      this.ngAfterViewInit();
    }
  }
}
NgxTypedJsComponent.ɵfac = function NgxTypedJsComponent_Factory(t) {
  return new (t || NgxTypedJsComponent)();
};
NgxTypedJsComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: NgxTypedJsComponent,
  selectors: [["ngx-typed-js"]],
  viewQuery: function NgxTypedJsComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.content = _t.first);
    }
  },
  inputs: {
    cursorColor: "cursorColor",
    strings: "strings",
    stringsElement: "stringsElement",
    typeSpeed: "typeSpeed",
    startDelay: "startDelay",
    backSpeed: "backSpeed",
    smartBackspace: "smartBackspace",
    shuffle: "shuffle",
    backDelay: "backDelay",
    fadeOut: "fadeOut",
    fadeOutClass: "fadeOutClass",
    fadeOutDelay: "fadeOutDelay",
    loop: "loop",
    loopCount: "loopCount",
    showCursor: "showCursor",
    cursorChar: "cursorChar",
    autoInsertCss: "autoInsertCss",
    attr: "attr",
    bindInputFocusEvents: "bindInputFocusEvents",
    contentType: "contentType"
  },
  outputs: {
    completed: "completed",
    preStringTyped: "preStringTyped",
    stringTyped: "stringTyped",
    lastStringBackspaced: "lastStringBackspaced",
    typingPaused: "typingPaused",
    typingResumed: "typingResumed",
    reset: "reset",
    stopped: "stopped",
    started: "started",
    destroyed: "destroyed"
  },
  features: [i0.ɵɵNgOnChangesFeature],
  ngContentSelectors: _c1,
  decls: 3,
  vars: 0,
  consts: [["wrapper", ""]],
  template: function NgxTypedJsComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "div", null, 0);
      i0.ɵɵprojection(2);
      i0.ɵɵelementEnd();
    }
  },
  styles: ["[_nghost-%COMP%] > .typing[_ngcontent-%COMP%]{display:inline}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxTypedJsComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-typed-js',
      template: "<div #wrapper>\n  <ng-content></ng-content>\n</div>\n",
      styles: [":host>.typing{display:inline}\n"]
    }]
  }], null, {
    cursorColor: [{
      type: Input
    }],
    strings: [{
      type: Input
    }],
    stringsElement: [{
      type: Input
    }],
    typeSpeed: [{
      type: Input
    }],
    startDelay: [{
      type: Input
    }],
    backSpeed: [{
      type: Input
    }],
    smartBackspace: [{
      type: Input
    }],
    shuffle: [{
      type: Input
    }],
    backDelay: [{
      type: Input
    }],
    fadeOut: [{
      type: Input
    }],
    fadeOutClass: [{
      type: Input
    }],
    fadeOutDelay: [{
      type: Input
    }],
    loop: [{
      type: Input
    }],
    loopCount: [{
      type: Input
    }],
    showCursor: [{
      type: Input
    }],
    cursorChar: [{
      type: Input
    }],
    autoInsertCss: [{
      type: Input
    }],
    attr: [{
      type: Input
    }],
    bindInputFocusEvents: [{
      type: Input
    }],
    contentType: [{
      type: Input
    }],
    completed: [{
      type: Output
    }],
    preStringTyped: [{
      type: Output
    }],
    stringTyped: [{
      type: Output
    }],
    lastStringBackspaced: [{
      type: Output
    }],
    typingPaused: [{
      type: Output
    }],
    typingResumed: [{
      type: Output
    }],
    reset: [{
      type: Output
    }],
    stopped: [{
      type: Output
    }],
    started: [{
      type: Output
    }],
    destroyed: [{
      type: Output
    }],
    content: [{
      type: ViewChild,
      args: ['wrapper', {
        static: true
      }]
    }]
  });
})();
class NgxTypedJsModule {}
NgxTypedJsModule.ɵfac = function NgxTypedJsModule_Factory(t) {
  return new (t || NgxTypedJsModule)();
};
NgxTypedJsModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgxTypedJsModule
});
NgxTypedJsModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxTypedJsModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxTypedJsComponent],
      imports: [],
      exports: [NgxTypedJsComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-typed-js
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxTypedJsComponent, NgxTypedJsModule };
