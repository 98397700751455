export const environment = {
  API_URL: 'https://admin.dialect-ai.com/api',
  VERIFY_UPLOAD_FILES_URL: 'https://processor.dialect-ai.com/verify-files',
  LANGCHAIN_CHATBOAT_URL: 'https://langchain.dialect-ai.com',
  LANGCHAIN_CHATBOAT_URL_1: ' https://api.dialect-ai.com/',
  // STRIPE_KEY:
  //   'pk_test_51NgPyEJ41ABvy6vnWw4lJYfNgk7IE1TuguKDr40OOUNZgnu0j0p4eWr7CMp8Px2PGKvdA1myvBLy2URWrvKhJIoB00NCLTvd2h',
  STRIPE_KEY:
    'pk_live_51NgPyEJ41ABvy6vn5DbNZUb6k9qYNS6dgCo820lzfqzrOWNqSliTfzoWc96VPvSYVp1s4XQVYYD6KTxhsKBijxqy00ly2MqgAr',
  PAYPAL_CLIENT_ID:
    'ATmTSilJhv8fAYyrAIQ2NS4jjsGYLKrZuURFmAorgxRSZPMq2o3K_6x3Odd6joKiukvbsEPqxSQL9nTd',
};
