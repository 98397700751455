import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, catchError, map, of } from 'rxjs';
import { environment } from '../enviroments/environment';
import { PORTAL_IDENTITY } from '../enviroments/settings';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  isAuthenticated(token: any): Observable<boolean> {
    const url = `${environment.API_URL}/test`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get<any>(url, { headers }).pipe(
      map((response) => {
        return response.data.status === '202';
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  saveUserDetail(user: any): void {
    localStorage.setItem('user', JSON.stringify(user));
  }

  getUserDetail() {
    const userData = localStorage.getItem('user');
    return userData ? JSON.parse(userData) : null;
  }

  saveToken(token: string): void {
    localStorage.setItem('token', token);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  userlogin(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/userlogin`;
    return this.http.post<any>(url, requestBody);
  }

  modelmade(payload: any): Observable<any> {
    const url = `${environment.API_URL}/modelmade`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post(url, payload, { headers });
  }

  verifyUploadFiles(filesArray: any): Observable<any> {
    const url = environment.VERIFY_UPLOAD_FILES_URL;
    const verifyData = filesArray.map(
      (file: { data_type: any; data_key: any }, index: number) => ({
        id: index + 1,
        data_type: file.data_type,
        data_key: file.data_key,
      })
    );
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
    });
    return this.http.post<any>(url, verifyData);
  }

  useragents(): Observable<any> {
    const url = `${environment.API_URL}/useragents`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any>(url, null, { headers });
  }

  agentfbconnect(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/agentfbconnect`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  removeagentfb(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/removeagentfb`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  getpages(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/getpages`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  getportal(): Observable<any> {
    var portal_id = PORTAL_IDENTITY;
    const url = `${environment.API_URL}/getportal?portal_id=${portal_id}`;
    return this.http.get<any>(url);
  }

  changepassword(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/changepassword`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  getagentallconversation(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/getagentallconversation`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    const params = new HttpParams().set(
      'agent_id',
      requestBody.agent_id.toString()
    );
    return this.http.get<any>(url, { headers, params });
  }

  agentworkflows(): Observable<any> {
    const url = `${environment.API_URL}/agentworkflows`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, {}, { headers });
  }

  workflows(requestBody: any) {
    const url = `${environment.LANGCHAIN_CHATBOAT_URL}/workflows`;
    return this.http.post<any>(url, requestBody);
  }

  getworkflowresponses(): Observable<any> {
    const url = `${environment.API_URL}/getworkflowresponses`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

    return this.http.get<any>(url, { headers });
  }
}
